import React, {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
  MouseEvent,
} from "react";
import { useNavigate } from "react-router-dom";

import { ResultContext } from "../../context/result-context";

import { routes } from "../../routing";

import Answer from "../answer";
import Question from "./question";

import { questionsContent } from "./questions-content";

interface QuestionsProps {}

const Questions: FC<QuestionsProps> = memo(() => {
  const navigate = useNavigate();
  const { currentResult, setCurrentResult } = useContext(ResultContext);
  const [curQuestionNum, setCurQuestionNum] = useState<number>(0);

  const [variant, setVariant] = useState<"question" | "answer">("question");
  const [isRightAnswer, setIsRightAnswer] = useState(false);

  const data = questionsContent[curQuestionNum];

  const handleClickButton = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const value = event.currentTarget.dataset.target;
      const rightAnswer = questionsContent[curQuestionNum].answer;
      const isRight = Boolean(value === rightAnswer);

      if (isRight) {
        setCurrentResult(currentResult + 1);
      }

      setIsRightAnswer(isRight);
      setVariant("answer");
    },
    [curQuestionNum, currentResult, setCurrentResult]
  );

  const handleViewNextQuestion = useCallback(() => {
    if (curQuestionNum === 8) {
      navigate(routes.result.path);
    } else {
      setCurQuestionNum((curQuestionNum) => curQuestionNum + 1);
      setVariant("question");
    }
  }, [navigate, curQuestionNum]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <>
      {variant === "question" && (
        <Question
          num={curQuestionNum}
          data={data}
          onClick={handleClickButton}
        />
      )}

      {variant === "answer" && (
        <Answer
          question={curQuestionNum}
          onClick={handleViewNextQuestion}
          isRight={isRightAnswer}
        />
      )}
    </>
  );
});

export default Questions;
