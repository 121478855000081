import React, { FC, memo } from "react";

interface LogoMetaProps {
  className?: string;
}

const LogoMeta: FC<LogoMetaProps> = memo(({ className }) => {
  return (
    <a
      href="https://bemeta.co/?utm_source=partners&utm_medium=referral&utm_campaign=mailru-partners-logo_toxictest"
      target="_blank"
      rel="noreferrer"
    >
      <svg
        className={className ? className : ""}
        height="25"
        viewBox="0 0 104 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.712 23.9552H11.8485L3.40008 6.90394V23.9552H0V0.668945H4.01795L13.8057 20.3989L23.5934 0.668945H27.5605V23.9534H24.1604V6.90212L15.712 23.9552Z"
          fill="#F7EEDD"
        />
        <path
          d="M55.5589 13.5494H36.2905C36.703 18.0308 40.2576 21.5345 44.7389 21.5345C48.2426 21.5345 50.4578 20.1443 51.848 17.7219H55.3517C53.7035 22.1014 49.8909 24.6256 44.7389 24.6256C37.8879 24.6256 32.7869 19.5264 32.7869 12.261C32.7869 5.2555 37.5263 0 44.5318 0C50.9721 0 55.6588 5.2555 55.6588 12.0557C55.6606 12.5191 55.6733 13.2532 55.5589 13.5494ZM52.1588 10.4583C51.5918 6.33676 48.9132 3.09115 44.5336 3.09115C39.7942 3.09115 36.8575 6.07871 36.2905 10.4583H52.1588Z"
          fill="#F7EEDD"
        />
        <path
          d="M57.4778 0.668945H79.2176V3.76009H70.0477V23.9552H66.6476V3.76009H57.4778V0.668945Z"
          fill="#F7EEDD"
        />
        <path
          d="M103.342 9.01539V23.955H99.9416V21.0692C98.0353 23.2845 95.2531 24.6238 91.5441 24.6238C86.1341 24.6238 81.9108 22.1505 81.9108 17.0513C81.9108 11.694 86.3922 9.47879 91.7512 9.47879H99.9416V9.01539C99.9416 4.79028 96.7469 3.09115 92.42 3.09115C88.711 3.09115 86.1341 4.7921 85.1564 7.00551H81.4983C82.8885 2.62593 87.01 0 92.5236 0C98.8603 0 103.342 3.24561 103.342 9.01539ZM99.9416 12.5699H91.6476C87.7315 12.5699 85.3109 14.0128 85.3109 17.0513C85.3109 20.0897 87.8859 21.5326 91.5968 21.5326C97.0067 21.5326 99.9416 18.0817 99.9416 14.3727V12.5699Z"
          fill="#F7EEDD"
        />
      </svg>
    </a>
  );
});

export default LogoMeta;
