import React, { FC, memo } from "react";

import "./check-list-security.scss";

interface CheckListSecurityProps {}

const CheckListSecurity: FC<CheckListSecurityProps> = memo(() => {
  return (
    <section className="check-list-security">
      <h2 className="check-list-security__title">
        Как Почта Mail.ru защищает от&nbsp;токсичного поведения
      </h2>
      <div className="check-list-security__body">
        <div className="check-list-security__item">
          <svg
            className="check-list-security__icon"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse cx="30" cy="30" rx="30" ry="30" fill="#68D3FF" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M29.9092 19C35.9296 19 40.8183 23.9014 40.8183 29.9375C40.8183 35.9737 35.9296 40.8751 29.9092 40.8751C23.8887 40.8751 19 35.9737 19 29.9375C19 23.9014 23.8887 19 29.9092 19ZM29.4045 31.5658C28.9013 31.3648 28.5454 30.8713 28.5454 30.2957V25.8359C28.5454 25.0812 29.1563 24.4687 29.9091 24.4687C30.6618 24.4687 31.2727 25.0812 31.2727 25.8359V29.4904L33.5009 30.7797C34.1527 31.157 34.3764 31.9937 34 32.6472C33.6236 33.3008 32.7891 33.525 32.1372 33.1476L29.4045 31.5658Z"
              fill="white"
            />
          </svg>
          <div className="check-list-security__itemtitle">
            Отложенная
            <br />
            отправка
          </div>
          <p className="check-list-security__itemtext">
            Чтобы не&nbsp;тревожить коллег по&nbsp;ночам
          </p>
        </div>
        <div className="check-list-security__item">
          <svg
            className="check-list-security__icon"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse cx="30" cy="30" rx="30" ry="30" fill="#71CEB1" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22 24.5439C22 23.5881 22.7397 22.9333 23.4992 22.9333H35.4993C36.2608 22.9333 37 23.5886 37 24.5439V38.9947C37 39.0119 36.9974 39.0261 36.9936 39.0375L33.956 35.8526L33.5111 35.3863H32.8589H23.4992C22.7397 35.3863 22 34.7315 22 33.7757V24.5439ZM23.4992 20C20.9487 20 19 22.1024 19 24.5439V33.7757C19 36.2172 20.9487 38.3196 23.4992 38.3196H32.2068L34.8378 41.0782L34.8377 41.0783L34.8494 41.0903L34.8539 41.0949L34.8544 41.0954L34.8589 41.1L34.8592 41.1003L34.8607 41.1018L34.8631 41.1042C35.3986 41.6498 36.1547 42 36.9996 42C38.7217 42 40 40.5878 40 38.9947V24.5439C40 22.102 38.0509 20 35.4993 20H23.4992ZM26.497 28.8C26.497 27.9904 25.825 27.3333 24.9985 27.3333C24.172 27.3333 23.5 27.9904 23.5 28.8C23.5 29.6096 24.172 30.2667 24.9985 30.2667C25.825 30.2667 26.497 29.6096 26.497 28.8ZM30.9955 28.8C30.9955 27.9904 30.325 27.3333 29.4985 27.3333C28.6705 27.3333 28 27.9904 28 28.8C28 29.6096 28.6705 30.2667 29.4985 30.2667C30.325 30.2667 30.9955 29.6096 30.9955 28.8ZM33.9985 27.3333C34.825 27.3333 35.4955 27.9904 35.4955 28.8C35.4955 29.6096 34.825 30.2667 33.9985 30.2667C33.1705 30.2667 32.5 29.6096 32.5 28.8C32.5 27.9904 33.1705 27.3333 33.9985 27.3333Z"
              fill="white"
            />
          </svg>
          <div className="check-list-security__itemtitle">
            Напоминания
            <br />
            о&nbsp;событиях
          </div>
          <p className="check-list-security__itemtext">
            Чтобы не&nbsp;опаздывать на&nbsp;встречи
          </p>
        </div>
        <div className="check-list-security__item">
          <svg
            className="check-list-security__icon"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse cx="30" cy="30" rx="30" ry="30" fill="#FFD913" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.1725 29.999L22.5865 25.413C21.8045 24.633 21.8045 23.365 22.5865 22.585C23.3665 21.805 24.6345 21.805 25.4145 22.585L30.0005 27.171L34.5865 22.585C35.3665 21.805 36.6345 21.805 37.4145 22.585C38.1945 23.365 38.1945 24.633 37.4145 25.413L32.8285 29.999L37.4145 34.585C38.1945 35.365 38.1945 36.633 37.4145 37.413C36.6345 38.193 35.3665 38.193 34.5865 37.413L30.0005 32.827L25.4145 37.413C24.6345 38.193 23.3665 38.193 22.5865 37.413C21.8045 36.633 21.8045 35.365 22.5865 34.585L27.1725 29.999V29.999Z"
              fill="white"
            />
          </svg>
          <div className="check-list-security__itemtitle">
            Отмена
            <br />
            отправки
          </div>
          <p className="check-list-security__itemtext">
            Чтобы исправить грубое письмо
          </p>
        </div>
        <div className="check-list-security__item">
          <svg
            className="check-list-security__icon"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse cx="30" cy="30" rx="30" ry="30" fill="#FF7EB7" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M32.5 39.5161V39.5H27.5C27.5 39.9552 27.6219 40.3822 27.8349 40.75C28.2675 41.497 29.0755 42 30 42C30.9245 42 31.7326 41.497 32.1651 40.75C32.3756 40.3865 32.4971 39.9653 32.5 39.5161ZM38.75 38.25V37.4167C38.75 36.8452 38.6521 36.2819 38.4641 35.75C38.2961 35.275 38.0563 34.8251 37.7501 34.4168L37.4232 33.981C36.6617 32.9656 36.25 31.7305 36.25 30.4613V28.25C36.25 24.8005 33.4495 22 30 22C26.5505 22 23.75 24.8005 23.75 28.25V30.4613C23.75 31.7305 23.3382 32.9656 22.5767 33.981L22.2499 34.4168C21.9437 34.8251 21.7039 35.275 21.5359 35.75C21.3479 36.2819 21.25 36.8452 21.25 37.4167V38.25H38.75ZM24.375 35.75H35.625L35.4233 35.481C34.3373 34.0329 33.75 32.2715 33.75 30.4613V28.25C33.75 26.1812 32.0688 24.5 30 24.5C27.9312 24.5 26.25 26.1812 26.25 28.25V30.4613C26.25 32.2715 25.6628 34.0329 24.5768 35.481L24.375 35.75Z"
              fill="white"
            />
          </svg>
          <div className="check-list-security__itemtitle">
            Напоминания
            <br />
            о&nbsp;письмах
          </div>
          <p className="check-list-security__itemtext">
            Чтобы не&nbsp;забыть ответить
          </p>
        </div>
      </div>
    </section>
  );
});

export default CheckListSecurity;
