import React, { FC, memo } from "react";

import Book from "./book.png";

import "./check-list-summary.scss";

import CheckListSignature from "../check-list-signature";

interface CheckListSummaryProps {}

const CheckListSummary: FC<CheckListSummaryProps> = memo(() => {
  return (
    <section className="check-list-summary">
      <h2 className="check-list-summary__title">Выводы</h2>
      <div className="check-list-summary__body">
        <div className="check-list-summary__descr">
          <CheckListSignature />
          <p>
            Самое очевидное решение при встрече с&nbsp;токсичным
            поведением&nbsp;&mdash; дистанцироваться, но&nbsp;в&nbsp;командной
            работе сложно просто закрыться от&nbsp;кого-то из&nbsp;коллег.
            Гораздо эффективнее научиться экологично реагировать
            на&nbsp;деструктив, и&nbsp;вот какие решения мы&nbsp;нашли:
          </p>
          <ol className="check-list__ol">
            <li>Отмечать плюсы</li>
            <li>Озвучивать правила</li>
            <li>Обозначать свои границы</li>
            <li>Договариваться на&nbsp;будущее</li>
            <li>Предлагать альтернативы</li>
          </ol>
        </div>
        <div className="check-list-summary__book">
          <div>
            <p>
              <b>Потренировать реакция на&nbsp;токсичность</b>
              <br />
              Полезное чтение с&nbsp;примерами и&nbsp;упражнениями, чтобы
              потренировать реакцию на&nbsp;токсичное поведение
            </p>
            <div className="check-list-summary__bookinfo">
              <img src={Book} alt="" width={59} />
              <div className="check-list-summary__bookname">
                Маршалл Розенберг
                <br />
                <b>Ненасильственное общение. Язык Жизни</b>
              </div>
            </div>
            <p>
              <b>Психотерапия</b>
              <br />
              Когда совсем тяжело и&nbsp;вы&nbsp;не&nbsp;знаете, как с&nbsp;этим
              справиться самому
            </p>
          </div>
        </div>
      </div>
    </section>
  );
});

export default CheckListSummary;
