import React, {
  ButtonHTMLAttributes,
  ReactElement,
  forwardRef,
  memo,
} from "react";

import "./button.scss";

interface CustomProps {
  href?: string;
  target?: "_blank" | "_self" | "_parent";
}

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & CustomProps;

const Button = memo(
  forwardRef<HTMLButtonElement, ButtonProps>(
    (
      { children, className, href, target = "_blank", ...props },
      ref
    ): ReactElement | null => {
      return (
        <>
          {href ? (
            <a
              href={href}
              className={`button ${className}`}
              target={target}
              rel={target === "_blank" ? "noopener noreferrer" : ""}
            >
              {children}
            </a>
          ) : (
            <button
              type="button"
              className={`button ${className}`}
              {...props}
              ref={ref}
            >
              {children}
            </button>
          )}
        </>
      );
    }
  )
);

export default Button;
