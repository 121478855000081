import React, { FC, memo, useCallback, MouseEvent } from "react";
import useCopy from "use-copy";

import "./social.scss";

interface SocialProps {
  className?: string;
  type: "result" | "footer";
  hasTitle?: boolean;
}

const Social: FC<SocialProps> = memo(({ className, type, hasTitle = true }) => {
  const [copied, copy, setCopied] = useCopy(window.location.href);

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    const type = event.currentTarget.dataset.share;
    const position = event.currentTarget.dataset.position;
    const href = encodeURIComponent(window.location.href);

    switch (type) {
      case "vk":
        window.open(
          "http://vk.com/share.php?url=" + href + "&from_sn=true&sn=vk",
          "",
          "height=480,width=640"
        );
        break;
      case "ok":
        window.open(
          "https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=" +
            href +
            "&from_sn=true&sn=ok",
          "",
          "height=480,width=640"
        );
        break;
      default:
        break;
    }

    window.dataLayer.push({
      event: "click-share-button",
      buttonType: `${type}_${position}`,
    });
  }, []);

  const handleCopyText = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const position = event.currentTarget.dataset.position;

      copy();

      setTimeout(() => {
        setCopied(false);
      }, 2000);

      window.dataLayer.push({
        event: "click-share-button",
        buttonType: `link_${position}`,
      });
    },
    [copy, setCopied]
  );

  return (
    <ul className={`social ${className}`}>
      {hasTitle && <li className="social__title">Поделиться:</li>}
      <li className="social__item">
        <button
          type="button"
          className="social__link -ok"
          onClick={handleClick}
          data-share="ok"
          data-position={type}
        >
          {type === "result" && (
            <svg
              className="gmt-click-button-ok-page-result"
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="44" height="44" rx="10" fill="#FF8A00" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.171 26.5816C15.4796 26.2563 15 25.5543 15 24.7403C15 23.6178 15.9108 22.707 17.0319 22.707C17.3692 22.707 17.6869 22.7902 17.9684 22.9354C19.1046 23.4922 20.3815 23.8039 21.731 23.8039C23.0835 23.8039 24.3619 23.4907 25.5011 22.9324C25.7795 22.7887 26.0957 22.707 26.4301 22.707C27.5511 22.707 28.4619 23.6178 28.4619 24.7403C28.4619 25.5543 27.9808 26.2593 27.2879 26.5831C26.5224 26.9598 25.7115 27.2609 24.8672 27.4757L27.6434 30.6226C28.3863 31.4637 28.3061 32.7497 27.4649 33.4926C26.6237 34.2339 25.3378 34.1537 24.5949 33.3125L21.7294 30.0658L18.864 33.3125C18.1211 34.1537 16.8352 34.2339 15.994 33.4926C15.1528 32.7497 15.0726 31.4637 15.8155 30.6226L18.5917 27.4757C17.7474 27.2594 16.938 26.9583 16.171 26.5816ZM21.731 10C25.1653 10 27.9551 12.7898 27.9551 16.2241C27.9551 19.6585 25.1653 22.4483 21.731 22.4483C18.2967 22.4483 15.5068 19.6585 15.5068 16.2241C15.5068 12.7898 18.2967 10 21.731 10ZM21.731 13.7808C23.079 13.7808 24.1743 14.8761 24.1743 16.2241C24.1743 17.5721 23.079 18.6675 21.731 18.6675C20.383 18.6675 19.2876 17.5721 19.2876 16.2241C19.2876 14.8761 20.383 13.7808 21.731 13.7808Z"
                fill="white"
              />
            </svg>
          )}

          {type === "footer" && (
            <svg
              className="gmt-click-button-ok-footer"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="31"
                height="31"
                rx="5.5"
                stroke="white"
                strokeOpacity="0.18"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.7319 18.3635C12.2997 18.1602 12 17.7214 12 17.2127C12 16.5111 12.5692 15.9419 13.2699 15.9419C13.4808 15.9419 13.6793 15.9939 13.8552 16.0846C14.5653 16.4326 15.3634 16.6274 16.2069 16.6274C17.0522 16.6274 17.8512 16.4317 18.5632 16.0828C18.7372 15.9929 18.9348 15.9419 19.1438 15.9419C19.8445 15.9419 20.4137 16.5111 20.4137 17.2127C20.4137 17.7214 20.113 18.1621 19.6799 18.3644C19.2015 18.5999 18.6947 18.788 18.167 18.9223L19.9021 20.8891C20.3664 21.4148 20.3163 22.2186 19.7906 22.6829C19.2648 23.1462 18.4611 23.0961 17.9968 22.5703L16.2059 20.5411L14.415 22.5703C13.9507 23.0961 13.147 23.1462 12.6212 22.6829C12.0955 22.2186 12.0454 21.4148 12.5097 20.8891L14.2448 18.9223C13.7172 18.7871 13.2113 18.5989 12.7319 18.3635ZM16.2069 8C18.3533 8 20.0969 9.74364 20.0969 11.8901C20.0969 14.0365 18.3533 15.7802 16.2069 15.7802C14.0604 15.7802 12.3168 14.0365 12.3168 11.8901C12.3168 9.74364 14.0604 8 16.2069 8ZM16.2069 10.363C17.0494 10.363 17.734 11.0476 17.734 11.8901C17.734 12.7326 17.0494 13.4172 16.2069 13.4172C15.3644 13.4172 14.6798 12.7326 14.6798 11.8901C14.6798 11.0476 15.3644 10.363 16.2069 10.363Z"
                fill="white"
              />
            </svg>
          )}
        </button>
      </li>
      <li className="social__item">
        <button
          type="button"
          className="social__link -vk"
          onClick={handleClick}
          data-share="vk"
          data-position={type}
        >
          {type === "result" && (
            <svg
              className="gmt-click-button-vk-page-result"
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="44" height="44" rx="10" fill="#4680C2" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.4679 22.6024C30.4679 22.6024 33.8744 17.6798 34.2119 16.0627C34.325 15.485 34.0763 15.1622 33.49 15.1622C33.49 15.1622 31.5291 15.1622 30.5357 15.1622C29.8589 15.1622 29.6118 15.4618 29.4083 15.9004C29.4083 15.9004 27.8108 19.388 25.8677 21.5861C25.2442 22.2962 24.9277 22.5097 24.582 22.5097C24.3042 22.5097 24.1766 22.273 24.1766 21.6325V16.0163C24.1766 15.2317 24.0862 15 23.4546 15H18.7204C18.3586 15 18.1325 15.2152 18.1325 15.5082C18.1325 16.248 19.2389 16.4152 19.2389 18.4197V22.5561C19.2389 23.3887 19.1936 23.7114 18.8092 23.7114C17.7949 23.7114 15.3817 20.1295 14.0282 16.0627C13.7584 15.2317 13.4645 15 12.6988 15H9.74461C9.31497 15 9 15.2996 9 15.7399C9 16.5476 9.92392 20.2917 13.5549 25.3071C15.9907 28.6804 19.1937 30.5061 22.0801 30.5061C23.839 30.5061 24.267 30.2065 24.267 29.4434V26.8547C24.267 26.2075 24.5158 25.9311 24.876 25.9311C25.2814 25.9311 25.9986 26.0635 27.6719 27.7568C29.657 29.6967 29.7927 30.5061 30.8749 30.5061H34.1893C34.5285 30.5061 34.8435 30.3439 34.8435 29.7662C34.8435 29.0048 33.8744 27.6409 32.3851 26.0238C31.7762 25.1912 30.7845 24.2891 30.4679 23.8736C30.0172 23.3887 30.1529 23.1106 30.4679 22.6024Z"
                fill="white"
              />
            </svg>
          )}

          {type === "footer" && (
            <svg
              className="gmt-click-button-vk-footer"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="31"
                height="31"
                rx="5.5"
                stroke="white"
                strokeOpacity="0.18"
              />
              <path
                d="M17.2592 22C11.4509 22 8.13804 17.8709 8 11H10.9094C11.005 16.043 13.1499 18.1792 14.8488 18.6196V11H17.5884V15.3493C19.2661 15.1621 21.0285 13.1802 21.6232 11H24.3628C23.9062 13.6867 21.9949 15.6687 20.6357 16.4835C21.9949 17.1441 24.1718 18.8729 25 22H21.9843C21.3366 19.9079 19.7227 18.2893 17.5884 18.0691V22H17.2592Z"
                fill="white"
              />
            </svg>
          )}
        </button>
      </li>
      <li className={copied ? "social__item -copy" : "social__item"}>
        <button
          type="button"
          className="social__link -link"
          onClick={handleCopyText}
          data-position={type}
        >
          {type === "result" && (
            <svg
              className="gmt-click-button-link-page-result"
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="44" height="44" rx="10" fill="#0077FF" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.1588 13.8276C25.0955 11.8908 28.2357 11.8908 30.1724 13.8276C32.1092 15.7643 32.1092 18.9045 30.1724 20.8412L27.2603 23.7534C26.7308 24.2829 25.8724 24.2829 25.3429 23.7534C24.8134 23.2239 24.8134 22.3655 25.3429 21.836L28.255 18.9238C29.1328 18.046 29.1328 16.6228 28.255 15.745C27.3772 14.8672 25.954 14.8672 25.0762 15.745L22.164 18.6571C21.6346 19.1866 20.7761 19.1866 20.2466 18.6571C19.7172 18.1277 19.7172 17.2692 20.2466 16.7397L23.1588 13.8276ZM26.1763 17.8238C26.7057 18.3533 26.7057 19.2117 26.1763 19.7412L19.7413 26.1762C19.2118 26.7057 18.3533 26.7057 17.8239 26.1762C17.2944 25.6467 17.2944 24.7882 17.8239 24.2588L24.2588 17.8238C24.7883 17.2943 25.6468 17.2943 26.1763 17.8238ZM13.8276 30.1724C15.7643 32.1092 18.9045 32.1092 20.8412 30.1724L23.7534 27.2603C24.2828 26.7308 24.2828 25.8723 23.7534 25.3429C23.2239 24.8134 22.3654 24.8134 21.836 25.3429L18.9238 28.255C18.046 29.1328 16.6228 29.1328 15.745 28.255C14.8672 27.3772 14.8672 25.954 15.745 25.0762L18.6571 22.164C19.1866 21.6345 19.1866 20.7761 18.6571 20.2466C18.1276 19.7171 17.2692 19.7171 16.7397 20.2466L13.8276 23.1588C11.8908 25.0955 11.8908 28.2357 13.8276 30.1724Z"
                fill="white"
              />
            </svg>
          )}

          {type === "footer" && (
            <svg
              className="gmt-click-button-link-footer"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="31"
                height="31"
                rx="5.5"
                stroke="white"
                strokeOpacity="0.18"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.8427 10.0564C18.2513 8.64786 20.535 8.64786 21.9436 10.0564C23.3521 11.465 23.3521 13.7487 21.9436 15.1573L19.8257 17.2752C19.4406 17.6603 18.8163 17.6603 18.4312 17.2752C18.0461 16.8901 18.0461 16.2658 18.4312 15.8807L20.5491 13.7628C21.1875 13.1244 21.1875 12.0893 20.5491 11.4509C19.9107 10.8125 18.8756 10.8125 18.2372 11.4509L16.1193 13.5688C15.7342 13.9539 15.1099 13.9539 14.7248 13.5688C14.3397 13.1837 14.3397 12.5594 14.7248 12.1743L16.8427 10.0564ZM19.0373 12.9627C19.4223 13.3478 19.4223 13.9721 19.0373 14.3572L14.3573 19.0372C13.9722 19.4223 13.3479 19.4223 12.9628 19.0372C12.5777 18.6521 12.5777 18.0278 12.9628 17.6427L17.6428 12.9627C18.0279 12.5777 18.6522 12.5777 19.0373 12.9627ZM10.0564 21.9436C11.465 23.3521 13.7487 23.3521 15.1573 21.9436L17.2752 19.8257C17.6603 19.4406 17.6603 18.8163 17.2752 18.4312C16.8901 18.0461 16.2658 18.0461 15.8807 18.4312L13.7628 20.5491C13.1244 21.1875 12.0893 21.1875 11.4509 20.5491C10.8125 19.9107 10.8125 18.8756 11.4509 18.2372L13.5688 16.1193C13.9539 15.7342 13.9539 15.1099 13.5688 14.7248C13.1837 14.3397 12.5594 14.3397 12.1743 14.7248L10.0564 16.8427C8.64786 18.2513 8.64786 20.535 10.0564 21.9436Z"
                fill="white"
              />
            </svg>
          )}
        </button>
      </li>
    </ul>
  );
});

export default Social;
