import Question1 from './question/question1.svg';
import QuestionMobile1 from './question/question1-mobile.svg';
import Question2 from './question/question2.svg';
import QuestionMobile2 from './question/question2-mobile.svg';
import Question3 from './question/question3.svg';
import QuestionMobile3 from './question/question3-mobile.svg';
import Question4 from './question/question4.svg';
import QuestionMobile4 from './question/question4-mobile.svg';
import Question5 from './question/question5.png';
import QuestionMobile5 from './question/question5-mobile.png';
import Question6 from './question/question6.svg';
import QuestionMobile6 from './question/question6-mobile.svg';
import Question7 from './question/question7.png';
import QuestionMobile7 from './question/question7-mobile.png';
import Question8 from './question/question8.svg';
import QuestionMobile8 from './question/question8-mobile.svg';
import Question9 from './question/question9.png';
import QuestionMobile9 from './question/question9-mobile.png';

export const questionsContent = [
  {
    src: Question1,
    srcMob: QuestionMobile1,
    answer: 'toxic'
  },
  {
    src: Question2,
    srcMob: QuestionMobile2,
    answer: 'toxic'
  },
  {
    src: Question3,
    srcMob: QuestionMobile3,
    answer: 'eco'
  },
  {
    src: Question4,
    srcMob: QuestionMobile4,
    answer: 'eco'
  },
  {
    src: Question5,
    srcMob: QuestionMobile5,
    answer: 'toxic'
  },
  {
    src: Question6,
    srcMob: QuestionMobile6,
    answer: 'eco'
  },
  {
    src: Question7,
    srcMob: QuestionMobile7,
    answer: 'toxic'
  },
  {
    src: Question8,
    srcMob: QuestionMobile8,
    answer: 'eco'
  },
  {
    src: Question9,
    srcMob: QuestionMobile9,
    answer: 'toxic'
  }  
];
