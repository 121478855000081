import React, { FC, memo } from "react";

import Logo from "./logo.svg";

interface LogoMailProps {
  className?: string;
}

const LogoMail: FC<LogoMailProps> = memo(({ className }) => {
  return (
    <a
      href="https://mail.ru/?utm_source=project_marketing&utm_medium=logo&utm_campaign=toxic"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src={Logo}
        alt="@mail проект VK"
        className={className ? className : ""}
      />
    </a>
  );
});

export default LogoMail;
