import React, { FC, memo } from "react";

import Avatar from "../check-list-autor/avatar.jpg";

import "./check-list-signature.scss";

interface CheckListSignatureProps {}

const CheckListSignature: FC<CheckListSignatureProps> = memo(() => {
  return (
    <div className="check-list-signature">
      <div className="check-list-signature__avatar">
        <img src={Avatar} alt="Ирина Горскина" />
      </div>
      <div>
        Комментарии психолога
        <br />
        <b>Ирины Горскиной:</b>
      </div>
    </div>
  );
});

export default CheckListSignature;
