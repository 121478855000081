import React, { FC, memo } from "react";

import "./check-list-footer.scss";

import Social from "../../../components/social";
import LogoMail from "../../../components/logo-mail";
import LogoMeta from "../../../components/logo-meta";

interface CheckListFooterProps {}

const CheckListFooter: FC<CheckListFooterProps> = memo(() => {
  return (
    <section className="check-list-footer">
      <div className="check-list-footer__body">
        <div className="check-list-footer__social">
          <Social type="footer" hasTitle={false} />
        </div>
        <div className="check-list-footer__logomail">
          <LogoMail />
        </div>
        <div className="check-list-footer__logometa">
          <LogoMeta />
        </div>
      </div>
    </section>
  );
});

export default CheckListFooter;
