import React, { MouseEvent, FC, memo } from "react";

import Content from "../../../components/content";
import Button from "../../../components/button";

import "./question.scss";

interface QuestionDataProps {
  src: string;
  srcMob: string;
  answer: string;
}

interface QuestionProps {
  num: number;
  data: QuestionDataProps;
  onClick: (event: MouseEvent<HTMLElement>) => void;
}

const Question: FC<QuestionProps> = memo(({ num, data, onClick }) => {
  return (
    <div className="question">
      <Content>
        <div className="question__number">
          <span>{num + 1}/9</span>
        </div>

        <picture className="question__img">
          <source media="(max-width: 767px)" srcSet={data.srcMob} />
          <img src={data.src} alt="" />
        </picture>

        <div className="question__buttons">
          <Button
            onClick={onClick}
            data-target="toxic"
            className="-transparent"
          >
            Токсично ☢️
          </Button>
          <Button onClick={onClick} data-target="eco" className="-transparent">
            Экологично 🍃
          </Button>
        </div>
      </Content>
    </div>
  );
});

export default Question;
