import React, { FC, memo, ReactNode } from "react";

import Header from "../header";
import Footer from "../footer";

import "./layout.scss";

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = memo(({ children }) => {
  return (
    <div className="page">
      <Header />

      <main className="page__body">{children}</main>

      <Footer />
    </div>
  );
});

export default Layout;
