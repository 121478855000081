import React, { FC, memo } from "react";

import Avatar from "./avatar.jpg";

import "./check-list-autor.scss";

interface CheckListAutorProps {}

const CheckListAutor: FC<CheckListAutorProps> = memo(() => {
  return (
    <section className="check-list-autor">
      <div className="check-list-autor__about">
        <div className="check-list-autor__avatar">
          <img src={Avatar} alt="Ирина Горскина" />
        </div>
        <p>
          Нам поможет
          <br />
          <b>Ирина Горскина</b>
        </p>
      </div>
      <ul className="check-list__ul">
        <li>психолог сервиса &laquo;Мета&raquo;</li>
        <li>магистр психологии и&nbsp;аспирант факультета психологии МГУ</li>
        <li>член Ассоциации когнитивно-поведенческой психотерапии</li>
      </ul>
    </section>
  );
});

export default CheckListAutor;
