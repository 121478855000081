import React, { FC, memo, useEffect } from "react";

import Content from "../../components/content";
import Button from "../../components/button";

import { answerContent } from "./answer-content";
import "./answer.scss";

interface AnswerProps {
  question: number;
  isRight: boolean;
  onClick: () => void;
}

const Answer: FC<AnswerProps> = memo(
  ({ question, isRight = false, onClick }) => {
    useEffect(() => {
      window.scrollTo({ top: 0, left: 0 });
    }, []);

    return (
      <div className="answer">
        <Content>
          <div className="answer__title">
            {isRight ? <>О да</> : <>О нет</>}
          </div>
          <p
            className="answer__text"
            dangerouslySetInnerHTML={{
              __html: answerContent[question].text,
            }}
          ></p>
          {answerContent[question].comment && answerContent[question].src ? (
            <div className="answer__info">
              <img
                src={answerContent[question].src}
                alt=""
                className="answer__infoimg"
              />

              <p
                className="answer__infotext"
                dangerouslySetInnerHTML={{
                  __html: answerContent[question].comment,
                }}
              ></p>

              <Button onClick={onClick}>Дальше</Button>
            </div>
          ) : (
            <Button onClick={onClick}>Дальше</Button>
          )}
        </Content>
      </div>
    );
  }
);

export default Answer;
