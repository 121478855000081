import AnswerImg1 from "./answer1.png";
import AnswerImg4 from "./answer4.png";
import AnswerImg5 from "./answer5.png";
import AnswerImg8 from "./answer8.png";

export const answerContent = [
  {
    text: 'Беспокойство коллег во&nbsp;внерабочее время, особенно желая привлечь внимание к&nbsp;своим заслугам&nbsp;&mdash; это нарушение чужих границ, а&nbsp;значит, токсичное поведение.',
    comment: '<b>Как экологично:</b> дождаться утра будней или воспользоваться отложенной отправкой в&nbsp;Почте Mail.ru, чтобы не&nbsp;тревожить коллег.',
    src: AnswerImg1
  },
  {
    text: 'Здесь заметны скрытые манипуляции: отсылка к&nbsp;руководству, мнимая поддержка и&nbsp;лесть. Это ставит сотрудника в&nbsp;положение, когда он&nbsp;не&nbsp;может прямо отказаться от&nbsp;срочной сверхурочной работы. <br /><br /><b>Как экологично:</b> договориться с&nbsp;руководством о&nbsp;реальных сроках и&nbsp;не&nbsp;ставить сотрудников в&nbsp;безвыходное положение. А&nbsp;если работа действительно не&nbsp;терпит отлагательств, то&nbsp;объяснить, почему это срочно, и&nbsp;предложить компенсацию сверхурочного труда.',
    comment: '',
  },
  {
    text: 'Предложить помощь&nbsp;&mdash; экологичное и&nbsp;рациональное решение, которое способствует прогрессу в&nbsp;работе.<br /><br />Возмутиться тем, что партнёр нарушает сроки&nbsp;&mdash; понятное желание. Но&nbsp;гневное письмо не&nbsp;ускорит процесс, а&nbsp;скорее усложнит деловые отношения.',
    comment: ''
  },
  {
    text: 'Несмотря на&nbsp;то, что встреча&nbsp;&mdash; в&nbsp;нерабочее время, приглашение на&nbsp;неё&nbsp;&mdash; мягкое и&nbsp;ненастойчивое. В&nbsp;нём объясняется выбор времени и&nbsp;важность мероприятия, а&nbsp;также проявляется забота о&nbsp;коллегах.',
    comment: 'Кстати, в&nbsp;<b>Календаре Mail.ru</b> можно автоматически добавлять во&nbsp;встречи ссылки на&nbsp;VK&nbsp;Звонки. А&nbsp;саму онлайн-встречу можно записать, чтобы потом поделиться с&nbsp;коллегами.',
    src: AnswerImg4
  },
  {
    text: 'Опаздывать на&nbsp;встречи, тратя чужое время, и&nbsp;врываться в&nbsp;разговор, перебивая и&nbsp;выставляя своё мнение на&nbsp;первый план,&nbsp;&mdash; это токсично.<br /><br /><b>Как экологично:</b> приходить вовремя, внимательно слушать коллег, делиться своими идеями по&nbsp;очереди.',
    comment: '<b>Календарь Mail.ru</b> поможет не&nbsp;опаздывать на&nbsp;встречи и&nbsp;не&nbsp;пропускать&nbsp;их. Он&nbsp;заранее напомнит о&nbsp;событии&nbsp;&mdash; в&nbsp;письме или пуш-уведомлении.',
    src: AnswerImg5
  },
  {
    text: 'В&nbsp;этом письме автор мягко отстаивает свои взгляды, а&nbsp;самое главное&nbsp;&mdash; предлагает конструктивные замечания и&nbsp;альтернативные решения.<br /><br />Если&nbsp;бы он&nbsp;просто осуждал чужие идеи, не&nbsp;предлагая ничего взамен,&nbsp;&mdash; тогда это было&nbsp;бы агрессивное, токсичное поведение. Как говорится: &laquo;Критикуешь&nbsp;&mdash; предлагай!&raquo;.',
    comment: ''
  },
  {
    text: 'Такие высказывания выходят за&nbsp;рамки делового этикета, в&nbsp;них чувствуются сексизм и&nbsp;объективация. Подобные &laquo;комплименты&raquo; нарушают границы сотрудниц и&nbsp;могут быть для них оскорбительны.<br /><br /><b>Как экологично:</b> в&nbsp;офисе уместны комплименты, которые отмечают профессиональные качества коллег.',
    comment: ''
  },
  {
    text: 'Даже в&nbsp;критический момент чёткие объяснения, ровный тон без повышения шрифтов и&nbsp;предложение альтернатив могут сделать общение не&nbsp;токсичным, а&nbsp;очень даже комфортным.',
    comment: 'Кстати, <b>в&nbsp;Почте Mail.ru</b> можно поставить напоминание о&nbsp;письме, если вы&nbsp;не&nbsp;готовы ответить сразу&nbsp;же, но&nbsp;понимаете, что к&nbsp;нему важно вернуться позже.',
    src: AnswerImg8
  },
  {
    text: 'Обесценивание чужих эмоций и&nbsp;неоправданный энтузиазм здорово &laquo;отравляют&raquo; рабочую среду.<br /><br /><b>Как экологично:</b> общаться честно и&nbsp;открыто, не&nbsp;вуалировать настоящие эмоции, но&nbsp;при этом быть вежливым и&nbsp;сдержанным.',
    comment: ''
  }
];
