import React, { FC, memo, ReactNode } from "react";

import "./content.scss";

interface ContentProps {
  children: ReactNode;
}

const Content: FC<ContentProps> = memo(({ children }) => {
  return <div className="content">{children}</div>;
});

export default Content;
