import React, { FC, memo } from "react";

import "./check-list.scss";

import Header from "../../components/header";

import CheckListStart from "./check-list-start";
import CheckListAutor from "./check-list-autor";
import CheckListInfo from "./check-list-info";
import CheckListSummary from "./check-list-summary";
import CheckListSecurity from "./check-list-security";
import CheckListFooter from "./check-list-footer";

interface CheckListProps {}

const CheckList: FC<CheckListProps> = memo(() => {
  return (
    <div className="check-list">
      <div className="check-list__body">
        <Header />
        <CheckListStart />

        <div className="check-list-frame">
          <CheckListAutor />
          <CheckListInfo />
          <CheckListSummary />
          <CheckListSecurity />
          <CheckListFooter />
        </div>
      </div>
    </div>
  );
});

export default CheckList;
