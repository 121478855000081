import React, { FC, memo } from "react";
import ImgInfo1 from "./img1.png";
import ImgInfo2 from "./img2.png";
import ImgInfo3 from "./img3.png";
import ImgInfo4 from "./img4.png";
import ImgInfo5 from "./img5.png";

import "./check-list-info.scss";

import CheckListSignature from "../check-list-signature";

interface CheckListInfoProps {}

const CheckListInfo: FC<CheckListInfoProps> = memo(() => {
  return (
    <>
      <section className="check-list-info">
        <h2 className="check-list-info__title">1. Нарушение границ</h2>
        <div className="check-list-info__item">
          <div className="check-list-info__img">
            <img src={ImgInfo1} alt="" />
          </div>
          <div className="check-list-info__descr">
            <CheckListSignature />
            <p>
              Такое поведение, конечно, нарушает границы. Но&nbsp;возможно,
              человек ведёт себя так не&nbsp;нарочно: например, родители
              в&nbsp;детстве без спроса заходили в&nbsp;его комнату,
              и&nbsp;он&nbsp;перенял опыт нарушения границ. Это не&nbsp;отменяет
              ответственности, но&nbsp;ответить можно конструктивно:
            </p>
            <ul className="check-list__ul">
              <li>Отметить плюсы</li>
              <li>Предложить альтернативы</li>
              <li>Озвучить правила</li>
              <li>Договориться на&nbsp;будущее</li>
            </ul>
          </div>
        </div>
        <div className="check-list-info__item -invert">
          <div className="check-list-info__img">
            <img src={ImgInfo2} alt="" />
          </div>
          <div className="check-list-info__descr">
            <CheckListSignature />
            <p>
              Если коллега пишет вам в&nbsp;нерабочее время,
              и&nbsp;вы&nbsp;об&nbsp;этом заранее не&nbsp;договаривались,
              то&nbsp;он&nbsp;нарушает ваши границы. Но&nbsp;не&nbsp;факт, что
              нарочно. Поэтому наиболее экологичным решением будет ответить
              в&nbsp;рабочее время и&nbsp;напомнить о&nbsp;правилах делового
              общения:
            </p>
            <ul className="check-list__ul">
              <li>Отметить плюсы</li>
              <li>Предложить альтернативы</li>
              <li>Озвучить правила</li>
              <li>Договориться на&nbsp;будущее</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="check-list-info">
        <h2 className="check-list-info__title">2. Манипуляция</h2>
        <div className="check-list-info__item">
          <div className="check-list-info__img">
            <img src={ImgInfo3} alt="" />
          </div>
          <div className="check-list-info__descr">
            <CheckListSignature />
            <p>
              Перед нами пример скрытой манипуляции: босс давит
              на&nbsp;ответственность и&nbsp;желание оправдать ожидания
              руководства, чтобы переложить решение задачи на&nbsp;сотрудника.
              В&nbsp;таком случае следует отвечать вежливо, но&nbsp;прямо,
              отстаивая свои границы:
            </p>
            <ul className="check-list__ul">
              <li>Поблагодарить</li>
              <li>Озвучить правила</li>
              <li>Предложить альтернативу</li>
              <li>Договориться на&nbsp;будущее</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="check-list-info">
        <h2 className="check-list-info__title">3. Нарушение деловой этики</h2>
        <div className="check-list-info__item -invert">
          <div className="check-list-info__img">
            <img src={ImgInfo4} alt="" />
          </div>
          <div className="check-list-info__descr">
            <CheckListSignature />
            <p>
              Нарушение деловой этики проявляется в&nbsp;том числе
              и&nbsp;в&nbsp;&laquo;доброжелательном сексизме&raquo;. Такие
              комментарии неуместны, особенно на&nbsp;деловом созвоне.
              И&nbsp;если у&nbsp;вас они вызывают недовольство, то&nbsp;это
              нормально, и&nbsp;стоит чётко обозначить свои границы:
            </p>
            <ul className="check-list__ul">
              <li>Защитить границы</li>
              <li>Договориться на&nbsp;будущее</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="check-list-info">
        <h2 className="check-list-info__title">4. Обесценивание</h2>
        <div className="check-list-info__item">
          <div className="check-list-info__img">
            <img src={ImgInfo5} alt="" />
          </div>
          <div className="check-list-info__descr">
            <CheckListSignature />
            <p>
              Такой приём часто применяют, когда хотят избежать
              ответственности&nbsp;&mdash; например, за&nbsp;переработки.
              Но&nbsp;возможно, руководитель искренне хотел приободрить коллег,
              просто неудачно выразился. В&nbsp;ответе стоит спокойно обозначить
              свои чувства и&nbsp;права:
            </p>
            <ul className="check-list__ul">
              <li>Выразить чувства</li>
              <li>Обозначить условия, если вы&nbsp;на&nbsp;них согласны</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
});

export default CheckListInfo;
