import React, { FC, memo } from "react";

import Button from "../../../components/button";

import "./check-list-start.scss";

interface CheckListStartProps {}

const CheckListStart: FC<CheckListStartProps> = memo(() => {
  return (
    <section className="check-list-start">
      <h1 className="check-list-start__title">
        Что делать, если коллеги ведут себя токсично
      </h1>
      <p className="check-list-start__text">
        Разберём на&nbsp;примерах из&nbsp;игры, как реагировать
        на&nbsp;токсичное поведение* коллег. И&nbsp;делать это экологично
        &mdash; с&nbsp;заботой о&nbsp;себе и&nbsp;рабочей атмосфере.
      </p>
      <p className="check-list-start__note">
        * Токсичное поведение&nbsp;&mdash; не&nbsp;научное, но&nbsp;популярное
        понятие. Наиболее близкий к&nbsp;нему аналог
        в&nbsp;психологии&nbsp;&mdash; &laquo;деструктивное поведение&raquo;,
        которое включает нарушение границ, манипуляции и&nbsp;агрессию.
      </p>
      <div className="check-list-start__button">
        <Button href="/check-list.pdf" className="-blue -size-s">
          Скачать .pdf
        </Button>
      </div>
    </section>
  );
});

export default CheckListStart;
