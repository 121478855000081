import React, { FC, memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { routes } from "../../routing";

import Content from "../../components/content";
import Button from "../../components/button";

import "./start.scss";

interface StartProps {}

const Start: FC<StartProps> = memo(() => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(routes.question.path);
  }, [navigate]);

  return (
    <div className="start">
      <Content>
        <h1 className="start__title">
          Токсично
          <br />
          или экологично?
        </h1>
        <p className="start__text">
          Зачастую на работе можно столкнуться с агрессией, манипуляциями и
          нарушением границ. Это так называемое «токсичное» поведение приводит к
          разладу в коллективе, выгоранию и разочарованию в работе.
          <br />
          <br />
          Чтобы этого не произошло, потренируйтесь в нашей игре отличать
          токсичное поведение от экологичного. И получите чек-лист от психолога
          «Меты» о том, как защитить свои границы.
        </p>
        <Button onClick={handleClick}>Начать игру</Button>
      </Content>
    </div>
  );
});

export default Start;
