import React, { FC, memo, useCallback, useContext, useEffect } from "react";

import { ResultContext } from "../../context/result-context";
import { useNavigate } from "react-router-dom";

import { routes } from "../../routing";
import Content from "../../components/content";
import Button from "../../components/button";

import Avatar from "./avatar.jpg";
import ResultSuccessImg from "./result-success.png";
import ResultFailImg from "./result-fail.png";

import "./result.scss";

interface ResultProps {}

const Result: FC<ResultProps> = memo(() => {
  const navigate = useNavigate();
  const { currentResult } = useContext(ResultContext);
  const isRight = currentResult > 7 ? true : false;

  const handleClick = useCallback(() => {
    navigate(routes.checkList.path);
  }, [navigate]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    window.dataLayer.push({
      event: "pageview",
      pagePath: routes.result.path,
    });
  }, []);

  return (
    <div className="result">
      <Content>
        <div className="result__body">
          <img
            src={isRight ? ResultSuccessImg : ResultFailImg}
            alt=""
            width={594}
            className="result__picture"
          />
          <div className="result__title">
            Ваш результат
            <div className="result__value">
              {currentResult} <span>из 9</span>
            </div>
          </div>

          <div className="result__text">
            {isRight ? (
              <>
                Вы&nbsp;тонко чувствуете разницу между токсичным
                и&nbsp;экологичным общением
              </>
            ) : (
              <>
                Вы&nbsp;не&nbsp;обязаны подстраиваться под чужие рамки, жертвуя
                своим благополучием
              </>
            )}
          </div>

          {!isRight && (
            <div className="result__note">
              Зачастую токсичное поведение кажется вам нормальным, потому что
              оно не&nbsp;порицается обществом и&nbsp;от&nbsp;этого привычно.
              Но&nbsp;вы&nbsp;не обязаны подстраиваться под чужие рамки, жертвуя
              своим благополучием.
            </div>
          )}

          <div className="result__info">
            <div className="result__infobody">
              <img
                src={Avatar}
                alt="Ирина Горскина"
                width={81}
                className="result__infoimg"
              />
              <p className="result__infotext">
                {isRight ? (
                  <>
                    А&nbsp;теперь&nbsp;&mdash; время узнать, как защититься
                    от&nbsp;&laquo;токсинов&raquo;. Помогут рекомендации Почты
                    Mail.ru и&nbsp;психолога &laquo;Меты&raquo; &mdash; Ирины
                    Горскиной.
                  </>
                ) : (
                  <>
                    Если в&nbsp;общении с&nbsp;коллегами вам некомфортно,
                    вы&nbsp;чувствуете давление, стресс и&nbsp;усталость,
                    обращайтесь к&nbsp;чек-листу от{" "}
                    <a
                      href="https://mail.ru/?utm_source=project_marketing&utm_medium=test&utm_campaign=toxic"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Почты Mail.ru
                    </a>{" "}
                    и&nbsp;психолога &laquo;
                    <a
                      href="https://bemeta.co/?utm_source=partners&utm_medium=referral&utm_campaign=mailru-partners-results_toxictest"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Меты
                    </a>
                    &raquo; &mdash;&nbsp;Ирины Горскиной. Он&nbsp;поможет вам
                    защитить свои границы и наладить эффективное
                    и&nbsp;экологичное общение на&nbsp;работе.
                  </>
                )}
              </p>
            </div>
            <Button onClick={handleClick}>Читать</Button>
          </div>
        </div>
      </Content>
    </div>
  );
});

export default Result;
