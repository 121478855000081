import React, { FC, memo } from "react";

import "./header.scss";
import LogoMail from "../logo-mail";
import LogoMeta from "../logo-meta";

interface HeaderProps {}

const Header: FC<HeaderProps> = memo(() => {
  return (
    <header className="header">
      <div className="header__wrap">
        <LogoMail className="header__logo" />

        <svg
          className="header__separator"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L23.5 23.5" stroke="#D2D2D2" />
          <path d="M23.5 1L1 23.5" stroke="#D2D2D2" />
        </svg>

        <LogoMeta className="header__meta" />
      </div>
    </header>
  );
});

export default Header;
