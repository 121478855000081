import React, { FC, memo } from "react";

import ProviderResult from "./provider/provider-result";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { routes } from "./routing";

import Start from "./pages/start";
import Questions from "./pages/questions";
import Result from "./pages/result";
import CheckList from "./pages/check-list";

import Layout from "./components/layout";

const App: FC = memo(() => {
  return (
    <ProviderResult>
      <Router>
        <Routes>
          <Route
            path={routes.question.path}
            element={
              <Layout>
                <Questions />
              </Layout>
            }
          />
          <Route
            path={routes.result.path}
            element={
              <Layout>
                <Result />
              </Layout>
            }
          />
          <Route path={routes.checkList.path} element={<CheckList />} />
          <Route
            path={routes.root.path}
            element={
              <Layout>
                <Start />
              </Layout>
            }
          />
          <Route path="*" element={<>Страница не найдена</>} />
        </Routes>
      </Router>
    </ProviderResult>
  );
});

export default App;
