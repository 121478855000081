  export const routes = {
    root: {
      path: "/",
    },
    result: {
      path: "/result",
    },
    question: {
      path: "/question/",
    },
    checkList: {
      path: "/check-list/",
    },
  };