import React, { FC, memo } from "react";

import Social from "../social";

import "./footer.scss";

interface FooterProps {}

const Footer: FC<FooterProps> = memo(() => {
  return (
    <footer className="footer">
      <div className="footer__wrap">
        <div className="footer__share">
          <Social type="footer" />
        </div>
        <div className="footer__copyright">@2022</div>
      </div>
    </footer>
  );
});

export default Footer;
