import React, {
  ReactNode,
  FC,
  memo,
  useState,
  useMemo,
  useCallback,
} from "react";
import { ResultContext } from "../../context/result-context";

interface ProviderResultProps {
  children: ReactNode;
}

const ProviderResult: FC<ProviderResultProps> = memo((props) => {
  const [result, setResult] = useState(0);

  const setCurrentResult = useCallback((value: number) => {
    setResult(value);
  }, []);

  const ResultContextProviderValue = useMemo(
    () => ({
      currentResult: result,
      setCurrentResult: setCurrentResult,
    }),
    [result, setCurrentResult]
  );

  return (
    <ResultContext.Provider value={ResultContextProviderValue}>
      {props.children}
    </ResultContext.Provider>
  );
});

export default ProviderResult;
